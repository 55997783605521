import React, { useRef } from 'react'

import useIntersectionObserver from '../../hooks/useInteractionObserver'
import { FeedItem } from '../../models/APIResource'
import { filterResourcesByType } from '../../models/RecommendResponse'
import { mergeCurrentContext } from '../../redux/slices/analyticsSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import { UrlMasonryGrid } from '../UrlMasonryGrid'

interface GridFeedProps {
  items: (FeedItem | undefined)[] | undefined
  onClick?: (index: string) => void
  isLoadingFeed: boolean
  loadMore: () => void
}

const LOADING_ITEMS = 5

export const GridFeed = ({ onClick, isLoadingFeed, items, loadMore }: GridFeedProps) => {
  const dispatch = useAppDispatch()
  const { page, hasReachedEnd } = useAppSelector(state => state.feed)
  const numOfLoadingItems = isLoadingFeed && !hasReachedEnd ? LOADING_ITEMS : undefined

  const urls = filterResourcesByType(items, 'url')

  const sentinelRef = useRef<HTMLDivElement>(null)
  useIntersectionObserver({ sentinelRef, loadMore, isLoading: isLoadingFeed && !hasReachedEnd, page })

  return (
    <div className="relative size-full">
      <div className="relative p-1.5" style={{ overflow: 'unset' }}>
        <UrlMasonryGrid
          urls={urls}
          loadingItems={numOfLoadingItems}
          onClick={index => {
            dispatch(
              mergeCurrentContext({
                page: 'CONTENT_VIEW',
              })
            )
            onClick?.(index)
          }}
        />
        <div ref={sentinelRef} className="absolute bottom-0 left-0 h-1/5 max-h-[20vh] w-full opacity-0"></div>
      </div>
    </div>
  )
}
