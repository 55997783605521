import { useEffect, useRef } from 'react'

import { useSegmentTrack } from './useSegmentAnalytics'

export const useTrackPageView = (properties?: object, options?: { skip: boolean }) => {
  const track = useSegmentTrack()
  const propertiesRef = useRef(properties)

  useEffect(() => {
    if (!options?.skip) track('pageView', propertiesRef.current)
  }, [track, options?.skip])
}
